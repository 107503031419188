import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "./wsr.css";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import uri from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./spurqlabs.png";
import jsPDF from "jspdf";
import { Document, Packer, Paragraph, TextRun, Header, Footer, Table, TableRow, TableCell, AlignmentType, ImageRun, WidthType } from 'docx';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';


const WeeklyStatusReport = ({
  selectedProject,
  selectedUsers,
  selectedTasks,
  startDate,
  endDate,
  selectedClient,
}) => {
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previousReports, setPreviousReports] = useState([]);
  const [error, setError] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [cellValue, setCellValue] = useState("");
  const [reportGenerated, setReportGenerated] = useState(false);
  const [approverNames, setApproverNames] = useState([]); // State for approver names
  const [selectedApprover, setSelectedApprover] = useState(null); // State for selected approver
  const [selectedReport, setSelectedReport] = useState(null);
  const [viewStatus, setViewStatus] = useState('');
  const [rejectionMessage, setRejectionMessage] = useState('');
  const [isReportGenerated, setIsReportGenerated] = useState(false);  // New state to track button label
  const [nextWeekPlan, setNextWeekPlan] = useState('');
  const [selectedViewStatus, setSelectedViewStatus] = useState([]);
  const [selectedPreparedBy, setSelectedPreparedBy] = useState('');
  const [preparedByOptions, setPreparedByOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [searchReportId, setSearchReportId] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const reportRef = useRef();
  const statusOptions = [
    { value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" },
  ];
  const viewStatusOptions = [
    { value: "", label: "ALL" }, // Added ALL option to display all reports
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];
  const preparedBy = localStorage.getItem("userName");
  const formatDate = (isoDate) => {
    return new Date(isoDate).toLocaleDateString('en-GB');
  };
  const fetchPreviousReports = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${uri}/api/wsr/preparedby`, {
        params: {
          preparedBy: preparedBy, // Pass necessary params if required
        },
      });

      if (response.status === 200) {
        setPreviousReports(response.data);

        // Extract unique clients and projects
        const uniqueClients = [...new Set(response.data.map(report => report.clientName))];
        const uniqueProjects = [...new Set(response.data.map(report => report.projectName))];

        // Format for dropdowns
        setClientOptions(uniqueClients.map(client => ({ value: client, label: client })));
        setProjectOptions(uniqueProjects.map(project => ({ value: project, label: project })));
      } else {
        setPreviousReports([]);
        toast.warning("No previous reports found.");
      }
    } catch (error) {
      console.error("Error fetching previous reports:", error);
      setPreviousReports([]);
    } finally {
      setLoading(false);
    }
  };


  // Ensure fetch is triggered when `selectedViewStatus` or `selectedPreparedBy` changes
  useEffect(() => {
    fetchPreviousReports();
  }, [selectedViewStatus, selectedPreparedBy]);

  const fetchApproverNames = async () => {
    try {
      const organization = localStorage.getItem("organization");
      if (!organization) return;

      setLoading(true);
      const response = await axios.get(`${uri}/api/approvername`, {
        params: { organization },
      });

      if (response.status === 200) {
        setApproverNames(response.data);
      } else {
        console.error("Failed to fetch approver names");
      }
    } catch (error) {
      console.error("Error fetching approver names:", error);
    } finally {
      setLoading(false);
    }
  };

  // Effect to fetch approver names on component mount
  useEffect(() => {
    fetchApproverNames();
  }, []);

  useEffect(() => {
    fetchPreviousReports();
  }, [viewStatus]);
  const options = approverNames.map((user) => ({
    value: `${user.firstName} ${user.lastName}`,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const handleChange = (selectedOption) => {
    setSelectedApprover(selectedOption.value); // Store selected approver's name
    setIsSubmitted(false); // Reset submission state
  };

  const handleSendForApproval = async () => {
    const organization = localStorage.getItem("organization");
    const preparedBy = localStorage.getItem("userName");
  
    if (!organization || !preparedBy || !selectedApprover) {
      console.error("Missing required fields");
      toast.error("Please fill all required fields.");
      return;
    }
  
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    const reportId = `${selectedProject[0]}-WSR-${formattedStartDate}-${formattedEndDate}`;
  
    const wsrPayload = {
      projectName: selectedProject[0],
      organization,
      reportId,
      approvedStatus: "Pending",
      preparedBy,
      approver: selectedApprover,
      clientName: selectedClient[0],
      startDate,
      endDate,
      reportData,
      nextWeekPlan,
    };
  
    try {
      const response = await axios.post(`${uri}/api/wsr`, wsrPayload, {
        headers: { "Content-Type": "application/json" },
      });
  
      if (response.status === 200) {
        toast.success("Report sent for approval successfully!");
  
        // Update submission state
        setIsSubmitted(true);
  
        // Create notifications
        await axios.post(`${uri}/api/notifications`, {
          userName: preparedBy,
          message: `You have sent a request for approval of ${reportId} to ${selectedApprover}`,
          approverName: selectedApprover,
          organization,
        });
  
        await axios.post(`${uri}/api/notifications`, {
          userName: selectedApprover,
          message: `You have received a request for approval for ${reportId} from ${preparedBy}`,
          approverName: selectedApprover,
          organization,
        });
      } else {
        toast.error("Failed to send the report for approval.");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Check if the specific error is for already approved WSR
        if (error.response.data.error === 'WSR already approved. Cannot save duplicate reports.') {
          toast.error("This report has already been approved. Cannot send it again.");
        } else {
          toast.error("Failed to send the report for approval. Please try again.");
        }
      } else {
        console.error("Error sending report for approval:", error);
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  


  const handleSelectReport = (report) => {
    setSelectedReport(report); // Set the selected report for detailed view
    setRejectionMessage(report.rejectionMessage || "");
  };
  const formatedDate = (isoDate) => new Date(isoDate).toLocaleDateString();

  if (loading) return <div>Loading...</div>;



  // Determine message for no reports found based on viewStatus
  const getNoReportsMessage = () => {
    if (selectedViewStatus.length === 0) {
      return "No WSR found for ALL statuses.";
    }

    const selectedStatuses = selectedViewStatus.join(", ");
    return `No WSR found for ${selectedStatuses} status${selectedViewStatus.length > 1 ? 'es' : ''}.`;
  };

  const formatedDuration = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };
  const isFiltersValid =
    selectedProject.length &&
    selectedUsers.length &&
    selectedTasks.length &&
    startDate &&
    endDate &&
    selectedClient.length;

  const exportPDF = () => {
    try {
      const reportContent = reportRef.current;

      const options = {
        margin: [1.5, 1, 1.5, 1], // Set top and bottom margins larger to prevent overlap with header and footer
        filename: 'WSR_Report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'landscape',
          hotfixes: ["px_scaling"],
        },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      };

      html2pdf().from(reportContent).set(options)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages();
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);

            // Header: Add logo in the top-left corner, adjusted to new margin
            pdf.addImage(logo, 'PNG', 0.5, 0.5, 1.5, 1.0); // Adjust as needed

            // Footer: "Created with SpurTrack" in the center, page number on the right
            pdf.setFontSize(10);
            pdf.text(
              "Created with SpurTrack",
              pageWidth / 2,
              pageHeight - 0.5, // Adjust footer position based on bottom margin
              { align: 'center' }
            );
            pdf.text(
              `Page ${i} of ${totalPages}`,
              pageWidth - 1,
              pageHeight - 0.5
            );
          }
        })
        .save()
    } catch (error) {
      console.error('Error exporting PDF:', error);
    }
  };

  const handleStatusChange = (value) => {
    setSelectedViewStatus((prev) => {
      if (value === "") {
        // If "ALL" is selected, include all options including "ALL" itself
        return prev.includes(value)
          ? [] // If "ALL" is deselected, clear all selections
          : viewStatusOptions.map(option => option.value); // Select all options, including "ALL"
      } else {
        const updatedStatus = prev.includes(value)
          ? prev.filter((item) => item !== value) // Remove selected value
          : [...prev, value]; // Add selected value

        // Check if all options (except "ALL") are selected
        const allOtherSelected = viewStatusOptions
          .filter(option => option.value !== "")
          .every(option => updatedStatus.includes(option.value));

        // If all other options are selected, include "ALL"
        if (allOtherSelected) {
          return [...updatedStatus, ""];
        }

        // Otherwise, exclude "ALL"
        return updatedStatus.filter(item => item !== "");
      }
    });
  };




  const handleClientChange = (value) => {
    setSelectedClients(prev =>
      prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
  };

  const handleProjectChange = (value) => {
    setSelectedProjects(prev =>
      prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
  };



  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${uri}/api/wsrreport`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectName: selectedProject[0],
          userName: selectedUsers,
          taskName: selectedTasks,
          startdate: startDate,
          enddate: endDate,
          clientName: selectedClient[0],
          preparedBy: preparedBy,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Fetched Data:", data);
      setReportData(data);

      setReportGenerated(true);
      setIsReportGenerated(true);

      // Send notification to the user that they have generated a report
      await fetch(`${uri}/api/notifications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: preparedBy,
          message: "You have generated a report",
          organization: localStorage.getItem("organization"),
        }),
      });

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const handleUpdateReport = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${uri}/api/wsrreport`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectName: selectedProject[0],
          userName: selectedUsers,
          taskName: selectedTasks,
          startdate: startDate,
          enddate: endDate,
          clientName: selectedClient[0],
          preparedBy: preparedBy,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setReportData(data);
      setReportGenerated(true);

      // Send notification to the user about the report update
      await fetch(`${uri}/api/notifications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: preparedBy,
          message: "You have updated the report",
          organization: localStorage.getItem("organization"),
        }),
      });

    } catch (error) {
      setError(error);
      toast.error("Error updating the report.");
    } finally {
      setLoading(false);
    }
  };

  const handleDoubleClick = (taskName, assignee, field, value) => {
    setEditingCell({ taskName, assignee, field });
    setCellValue(value);
  };

  const handleInputChange = (e) => {
    setCellValue(e.target.value);
  };

  const handleBlur = (taskName, assignee, field) => {
    if (reportData) {
      const updatedReportData = { ...reportData };
      updatedReportData.airesponse[taskName][assignee][field] = cellValue;
      setReportData(updatedReportData);
      setEditingCell(null);
    }
  };

  // Convert duration to HH:MM format
  const formatDuration = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };



  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const tasks = selectedReport?.reportData?.airesponse || {};
  const task = reportData?.airesponse || {};

  console.log("Tasks Data:", task);
  const exportWord = async () => {
    // Ensure selectedReport is available
    if (!selectedReport) {
      alert("No report selected for export.");
      return;
    }


    // Header with Logo and Report Title
    const header = new Header({
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              data: await fetch(logo).then((r) => r.arrayBuffer()), // Fetch logo image
              transformation: { width: 100, height: 40 }, // Adjust as needed
            }),
          ],
          alignment: AlignmentType.LEFT,
        }),
        new Paragraph({
          text: "Weekly Status Report",
          alignment: AlignmentType.CENTER,
          heading: "Title",
          spacing: { after: 200 },
        }),
      ],
    });

    // Footer with custom text
    const footer = new Footer({
      children: [
        new Paragraph({
          text: "Created with SpurTrack",
          alignment: AlignmentType.CENTER,
          size: 24,
        }),
      ],
    });

    // Info Section without table, maintaining layout
    const infoSection = [
      new Paragraph({
        text: `Project: ${selectedReport?.projectName || 'ProjectName'}`,
        alignment: AlignmentType.LEFT,
        spacing: { after: 100 },
      }),
      new Paragraph({
        text: `Client: ${selectedReport?.clientName || 'ClientName'}`,
        alignment: AlignmentType.LEFT,
        spacing: { after: 100 },
      }),
      new Paragraph({
        text: `Start Date: ${formatDate(selectedReport?.startDate) || 'StartDate'}`,
        alignment: AlignmentType.LEFT,
        spacing: { after: 100 },
      }),
      new Paragraph({
        text: `End Date: ${formatDate(selectedReport?.endDate) || 'EndDate'}`,
        alignment: AlignmentType.LEFT,
        spacing: { after: 100 },
      }),
      new Paragraph({
        text: `Prepared By: ${selectedReport?.preparedBy || 'Prepared By'}`,
        alignment: AlignmentType.LEFT,
        spacing: { after: 100 },
      }),
      new Paragraph({
        text: `Approved By: ${selectedReport?.approver || 'Approved By'}`,
        alignment: AlignmentType.LEFT,
        spacing: { after: 200 },
      }),
    ];

    // Task Table Headers
    const taskTableHeaders = [
      new TableRow({
        children: [
          new TableCell({ children: [new Paragraph("Task")], shading: { fill: "808080" } }),
          new TableCell({ children: [new Paragraph("Summary")], shading: { fill: "808080" } }),
          new TableCell({ children: [new Paragraph("Assignee")], shading: { fill: "808080" } }),
          new TableCell({ children: [new Paragraph("Duration (HH:MM)")], shading: { fill: "808080" } }),
          new TableCell({ children: [new Paragraph("Block/Comment")], shading: { fill: "808080" } }),
          new TableCell({ children: [new Paragraph("Status")], shading: { fill: "808080" } }),
        ],
      }),
    ];

    // Task Table Rows with Data
    const taskTableRows = Object.entries(tasks).flatMap(([taskName, assignees]) => {

      return Object.entries(assignees).map(([assignee, details]) => {

        return new TableRow({
          children: [
            new TableCell({ children: [new Paragraph(taskName || '')] }),
            new TableCell({ children: [new Paragraph(details.summary || '')] }),
            new TableCell({ children: [new Paragraph(details.userName || assignee)] }),
            new TableCell({ children: [new Paragraph(formatDuration(details.duration || 0))] }),
            new TableCell({ children: [new Paragraph(details.blockComment || 'N/A')] }),
            new TableCell({ children: [new Paragraph(details.status || 'Pending')] }),
          ],
        });
      });
    });



    const taskTable = new Table({
      rows: [...taskTableHeaders, ...taskTableRows],
      width: { size: 100, type: WidthType.PERCENTAGE },
    });

    // Next Week Plan Paragraph
    const nextWeekPlan = new Paragraph({
      text: `Next Week Plan: ${selectedReport.nextWeekPlan || ""}`,
      spacing: { before: 200, after: 200 },
    });

    // Final document with header, info, task table, and footer
    const doc = new Document({
      sections: [
        {
          headers: { default: header },
          footers: { default: footer },
          children: [
            ...infoSection,  // Adding the info section
            new Paragraph({ text: " " }), // spacer
            taskTable, // Task table with data
            nextWeekPlan, // Next week plan
          ],
        },
      ],
    });

    // Export document
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "Weekly_Status_Report.docx");
    });
  };


  const filteredReports = previousReports.filter(report => {
    const matchesSearch = report.reportId.toString().toLowerCase().includes(searchReportId.toLowerCase());

    const matchesViewStatus = selectedViewStatus.length === 0 || selectedViewStatus.includes(report.approvedStatus);

    const matchesClient = selectedClients.length === 0 || selectedClients.includes(report.clientName);

    const matchesProject = selectedProjects.length === 0 || selectedProjects.includes(report.projectName);

    return matchesSearch && matchesViewStatus && matchesClient && matchesProject;
  }).sort((a, b) => {
    // Sort by 'createdAt' in descending order (newest reports first)
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  });

  if (!isFiltersValid) {
    return (
      <div className="container">
        <div className="alert alert-warning" role="alert">
          Please select a <strong>Team</strong>, <strong>Client</strong>,{" "}
          <strong>Project</strong>, <strong>Task</strong>,{" "}
          <strong>Start and End dates</strong> from the filters above to
          generate the report.
        </div>
        <h1 className="text-center my-4">Weekly Status Report</h1>
        <div className="filter-container" style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Report ID"
              value={searchReportId}
              onChange={(e) => setSearchReportId(e.target.value)}
            />
          </div>
          <Dropdown autoClose="outside">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Status
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {viewStatusOptions.map((option) => (
                <Dropdown.Item
                  key={option.value}
                  onClick={() => handleStatusChange(option.value)}
                >
                  <input
                    type="checkbox"
                    checked={selectedViewStatus.includes(option.value)}
                    onChange={() => handleStatusChange(option.value)}
                    className="me-2"
                  />
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>



          <Dropdown autoClose="outside">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Client
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {clientOptions.map(option => (
                <Dropdown.Item key={option.value} onClick={() => handleClientChange(option.value)}>
                  <input
                    type="checkbox"
                    checked={selectedClients.includes(option.value)}
                    onChange={() => handleClientChange(option.value)}
                    className="me-2"
                  />
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown autoClose="outside">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Project Name
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {projectOptions.map(option => (
                <Dropdown.Item key={option.value} onClick={() => handleProjectChange(option.value)}>
                  <input
                    type="checkbox"
                    checked={selectedProjects.includes(option.value)}
                    onChange={() => handleProjectChange(option.value)}
                    className="me-2"
                  />
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {
          filteredReports.length === 0 ? (
            <div className="alert alert-info text-center">
              {error ? error.message : getNoReportsMessage()}{" "}
            </div>
          ) : (
            <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Report ID</th>
                    <th>Prepared By</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredReports.map((report, index) => (
                    <tr
                      key={report.reportId}
                      onClick={() => handleSelectReport(report)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{filteredReports.findIndex((_, i) => i === index) + 1}</td>
                      <td>{report.reportId}</td>
                      <td>{report.preparedBy}</td>
                      <td>{report.approvedStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        }


        {/* Bootstrap Modal for selected report */}
        <Modal
          show={!!selectedReport}
          onHide={() => setSelectedReport(null)}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Details</Modal.Title>
            {/* Export Dropdown */}
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Export
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={exportPDF}>Export to PDF</Dropdown.Item>
                <Dropdown.Item onClick={exportWord}>
                  Export to Word
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
            {selectedReport && (
              <>
                <div ref={reportRef}>
                  <div className="info-section">

                    <div className="row">
                      <div className="col-md-12">
                        <strong>Report ID: </strong>
                        <span>{selectedReport?.reportId || 'Report ID'}</span>
                      </div>
                      <div className="col-md-6">
                        <strong>Project: </strong>
                        <span>{selectedReport?.projectName || 'ProjectName'}</span>
                      </div>
                      <div className="col-md-6">
                        <strong>Client: </strong>
                        <span>{selectedReport?.clientName || 'ClientName'}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong>Start Date: </strong>
                        <span>{formatDate(selectedReport?.startDate) || 'StartDate'}</span>
                      </div>
                      <div className="col-md-6">
                        <strong>End Date: </strong>
                        <span>{formatDate(selectedReport?.endDate) || 'EndDate'}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <strong>Prepared By: </strong>
                        <span>{selectedReport?.preparedBy || 'Prepared By'}</span>
                      </div>
                      <div className="col-md-6">
                        <strong>Approved By: </strong>
                        <span>{selectedReport?.approver || 'Approved By'}</span>
                      </div>
                    </div>
                  </div>

                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th>Task</th>
                        <th>Summary</th>
                        <th>Assignee</th>
                        <th>Duration (HH:MM)</th>

                        <th>Block/Comment</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(tasks).map(([taskName, users]) => {
                        const assigneeEntries = Object.entries(users);
                        // Calculate total duration for this task group
                        const taskGroupTotalDuration = assigneeEntries.reduce((total, [_, details]) => {
                          return total + (details?.duration || 0);
                        }, 0);

                        return assigneeEntries.map(([assignee, details], index) => (
                          <tr key={`${taskName}-${assignee}`}>
                            {index === 0 && (
                              <td
                                rowSpan={assigneeEntries.length}
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {taskName}
                              </td>
                            )}
                            <td>{details.summary || ''}</td>
                            <td>{details.userName || ''}</td>
                            <td>{formatDuration(details.duration)}</td>
                            <td>{details.blockComment || 'N/A'}</td>
                            <td>{details.status || ''}</td>
                          </tr>
                        ));
                      })}
                      {/* Add overall total row */}
                      <tr className="table-secondary">
                        <td colSpan="3" style={{ textAlign: "start", fontWeight: "bold" }}>
                          Total Duration :
                        </td>
                        <td colSpan="2" style={{ textAlign: "center", fontWeight: "bold" }}>
                          {formatDuration(
                            Object.values(tasks).reduce((total, users) => {
                              return total + Object.values(users).reduce((userTotal, details) => {
                                return userTotal + (details?.duration || 0);
                              }, 0);
                            }, 0)
                          )} HH:MM
                        </td>
                        <td colSpan="2"></td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <label>Next Week Plan:</label>
                    <p style={{ whiteSpace: 'pre-line' }}>
                      {selectedReport.nextWeekPlan}
                    </p>
                  </div>
                  {selectedReport?.approvedStatus !== 'Approved' && selectedReport?.approvedStatus !== 'Pending' && (
                    <div>
                      <label>Rejection Message:</label>
                      <p style={{ whiteSpace: 'pre-line' }}>
                        {selectedReport.rejectionMessage}
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSelectedReport(null)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    );
  }

  return (
    <div className="container">
      {/* Generate Report Button */}
      <div className="text-center my-4">
        <button
          className="btn btn-primary"
          onClick={isReportGenerated ? handleUpdateReport : fetchData}  // Change function when updating
          disabled={!isFiltersValid}
        >
          {isReportGenerated ? 'Update Report' : 'Generate Report'}  {/* Conditionally change label */}
        </button>
      </div>

      <h1 className="text-center my-4">Weekly Status Report</h1>

      {reportGenerated && (
        <div>
          {/* Info Section */}
          <div className="info-section">
            <div className="row">
              <div className="col-md-6">
                <strong>Team: </strong>
                <span>{reportData?.projectName || "ProjectName"}</span>
              </div>
              <div className="col-md-6">
                <strong>Client/Customer: </strong>
                <span>{reportData?.clientName || "ClientName"}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <strong>Start Date: </strong>
                <span>{formatDate(reportData?.startdate) || "StartDate"}</span>
              </div>
              <div className="col-md-6">
                <strong>End Date: </strong>
                <span>{formatDate(reportData?.enddate) || "EndDate"}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <strong>Prepared By: </strong>
                <span>{reportData?.preparedBy || "Prepared By"}</span>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <strong className="mr-2">Approved By: </strong>
                  {loading ? (
                    <Select
                      options={[]}
                      isLoading={true}
                      placeholder="Loading..."
                      className="flex-grow-1"
                    />
                  ) : (
                    <Select
                      options={options}
                      onChange={handleChange}
                      isSearchable
                      placeholder="Select Approver"
                      name="approverName"
                      className="flex-grow-1"
                      required
                    />
                  )}
                </div>

                {selectedApprover && !isSubmitted && (
                  <div className="d-flex justify-content-end my-3">
                    <button
                      className="btn btn-success"
                      onClick={handleSendForApproval}
                    >
                      Send for Approval
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Report Table */}
          <table className="table table-bordered table-responsive">
            <thead>
              <tr>
                <th>Task</th>
                <th>Summary</th>
                <th>Assignee</th>
                <th>Duration (HH:MM)</th>
                
                <th>Block/Comment</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {reportData && reportData.airesponse &&
                Object.entries(reportData.airesponse).map(([taskName, users]) => {
                  const assigneeEntries = Object.entries(users);
                  // Calculate total duration for this task group
                  const taskGroupTotalDuration = assigneeEntries.reduce((total, [_, details]) => {
                    return total + (details?.duration || 0);
                  }, 0);

                  return assigneeEntries.map(([assignee, details], index) => (
                    <tr key={`${taskName}-${assignee}`}>
                      {index === 0 && (
                        <>
                          <td
                            rowSpan={assigneeEntries.length}
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {taskName}
                          </td>
                        </>
                      )}
                      <td
                        onDoubleClick={() =>
                          handleDoubleClick(taskName, assignee, "summary", details?.summary)
                        }
                      >
                        {editingCell &&
                          editingCell.taskName === taskName &&
                          editingCell.assignee === assignee &&
                          editingCell.field === "summary" ? (
                          <textarea
                            value={cellValue}
                            onChange={handleInputChange}
                            onBlur={() => handleBlur(taskName, assignee, "summary")}
                            rows="10"
                            className="form-control"
                            autoFocus
                          />
                        ) : (
                          details?.summary || ""
                        )}
                      </td>
                      <td>{details?.userName || ""}</td>
                      <td>{formatDuration(details?.duration)}</td>
                      
                      <td
                        onDoubleClick={() =>
                          handleDoubleClick(
                            taskName,
                            assignee,
                            "blockComment",
                            details?.blockComment
                          )
                        }
                      >
                        {editingCell &&
                          editingCell.taskName === taskName &&
                          editingCell.assignee === assignee &&
                          editingCell.field === "blockComment" ? (
                          <textarea
                            value={cellValue}
                            onChange={handleInputChange}
                            onBlur={() => handleBlur(taskName, assignee, "blockComment")}
                            rows="8"
                            className="form-control"
                            autoFocus
                          />
                        ) : (
                          details?.blockComment || "N/A"
                        )}
                      </td>
                      <td>
                        <Select
                          options={statusOptions}
                          value={
                            statusOptions.find((option) => option.value === details?.status) ||
                            null
                          }
                          onChange={(selectedOption) => {
                            const updatedReportData = { ...reportData };
                            updatedReportData.airesponse[taskName][assignee].status =
                              selectedOption.value;
                            setReportData(updatedReportData);
                          }}
                          isSearchable={false}
                          className="status-dropdown"
                        />
                      </td>
                    </tr>
                  ));
                })}
              {/* Add total row */}
              {reportData && reportData.airesponse && (
                <tr className="table-secondary">
                  <td colSpan="3" style={{  fontWeight: "bold" }}>
                    Total Duration:
                  </td>
                  <td colSpan="2" style={{ textAlign: "center", fontWeight: "bold" }}>
                    {formatDuration(
                      Object.values(reportData.airesponse).reduce((total, users) => {
                        return total + Object.values(users).reduce((userTotal, details) => {
                          return userTotal + (details?.duration || 0);
                        }, 0);
                      }, 0)
                    )} HH:MM
                  </td>
                  <td colSpan="2"></td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Next Week Plan */}
          <div className="next-week-plan">
            <strong>Next Week Plan:</strong>
            <div className="text-area">
              <textarea
                placeholder="Next Week plan details"
                rows="4"
                className="form-control"
                value={nextWeekPlan}
                onChange={(e) => setNextWeekPlan(e.target.value)}  // Update state on change
              ></textarea>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default WeeklyStatusReport;